* {
  font-family: 'Roboto', sans-serif;
  font-size: 1.6rem;
  line-height: 1.3;
}

body {
  color: #ffffff;
  background-color: #000000;
  overflow: hidden;
}

html,
body {
  -webkit-tap-highlight-color: transparent;
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

*,
*::before,
*::after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility;
  box-sizing: border-box;
}

a,
a:visited,
a:hover,
a:active {
  text-decoration: none;
}

.gm-style-cc {
  display: none;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  background-color: black !important;
  color: white !important;
  -webkit-box-shadow: 0 0 0 1000px black inset !important;
  -webkit-text-fill-color: white !important;
  background-clip: content-box !important;
}

/* remove calendar from input date in Chrome */
input[type='date']::-webkit-calendar-picker-indicator,
input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.browser-detection {
  text-align: center;
  margin: auto auto;
  width: 500px;
  max-width: 100%;
  padding: 64px 32px;
  min-width: 0;
  min-height: 0;
  overflow-y: auto;
  overflow-x: hidden;

  .logo {
    margin: auto auto 16px auto;
  }

  table {
    margin: auto auto;

    td {
      padding: 24px 30px 0;
      font-family: Roboto, serif;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.3px;
      color: #ffffff;
    }

    a {
      display: block;
      margin-bottom: 14px;
    }
  }

  h1 {
    font-family: Roboto, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #ffffff;
    max-width: 100%;
  }
}

//This remove the outline from pure-react-carousel
.focusRing___1airF.carousel__slide-focus-ring,
.carousel__slider,
.carousel__slider-tray-wrapper,
.carousel__next-button,
.carousel__back-button {
  outline: none !important;
}
.carousel__dot {
  padding: 1px;
  background-color: white;
  border-color: white;
  border-radius: 50%;
  border-style: solid;
  opacity: 0.5;
}
.carousel__dot--selected {
  opacity: 1;
}
.carousel__dot-group {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  > button:not(:first-child) {
    margin-left: 8px;
  }
}
